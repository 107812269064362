import { GameDesignConfig } from './../../share/models/game-design-config';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isNumeric } from '../../share';

@Injectable()
export class LuckyWheelService {
  constructor(
    private http: HttpClient
  ) {
    this.setHeaderRequest();
  }
  access_token;
  params = {};
  header;
  method_identificate: string;
  getCampaign(param): Observable<any> {
    let object: any = {};
    if (param.campaign_id) {
      return this.http.get(`${environment.urlDev}${environment.public}campaign/${param.campaign_id}`, {params: { form: 'true' }});
    } else {
      // if (!isNumeric(param.target)) {
      object.target = decodeURIComponent(param.target);
      object.form = true;
      // } else {
      //   object.target = `${environment.urlTemplateMKT}${param.target}`;
      // }
      return this.http.get(`${environment.urlDev}${environment.public}campaign/get-campaign`, { params: object });
    }
  }
  getDetailByID(id: any, params?): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${id}`, {params});
  }
  getTurnFromCode(body): Observable<any> {
    if (body.target) body.target = decodeURIComponent(body.target);
    return this.http.post(`${environment.urlDev}${environment.auth}customer/campaign/game`, body);
  }

  getTheme(id:number):Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${id}/themes`);
  }
  getThemeGame(id:number):Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${id}/theme_game`);
  }

  getTurnCount(param): Observable<any> {
    this.setHeaderRequest();
    const object = {
      campaign_id: param.campaign_id,
      phone: param.phone,
      target: decodeURIComponent(param.target),
      device_id: param.device_id
    };
    if (!param.phone) {
      delete object.phone;
    }
    if (!param.device_id) {
      delete object.device_id;
    }
    if (!param.target) {
      delete object.target;
    }
    Object.assign(object, this.params);
    console.log(object);
    if (this.access_token) {
      // tslint:disable-next-line:max-line-length
      return this.http.get(`${environment.urlDev}${environment.auth}customer/campaign/${object.campaign_id}/game/lucky-wheel`, { headers: this.header });
    }
    return this.http.get(`${environment.urlDev}${environment.public}campaign/time-play`, { params: object });
  }

  enterCodeGetTurn(body): Observable<any> {
    if (body.target) body.target = decodeURIComponent(body.target);
    this.setHeaderRequest();
    if (this.method_identificate === 'account_icheck') {
      return this.http.post(`${environment.urlDev}${environment.auth}customer/campaign/game`, body, { params: this.params, headers: this.header });
    }
    return this.http.post(`${environment.urlDev}${environment.public}campaign/take-time-play`, body, { params: this.params });
  }

  playGame(body): Observable<any> {
    if (body.target) body.target = decodeURIComponent(body.target);
    this.setHeaderRequest();
    if (this.method_identificate === 'account_icheck') {
      return this.http.post(`${environment.urlDev}${environment.auth}campaign/game/play`, body, { params: this.params, headers: this.header });
    }
    return this.http.post(`${environment.urlDev}${environment.public}campaign/game/play`, body, { params: this.params });
  }
  getTopWinner(param): Observable<any> {
    if (param.target) param.target = decodeURIComponent(param.target);
    return this.http.get(`${environment.urlDev}${environment.public}winner/top_winner?campaign_id=${param}`, { params: this.params });
  }

  getGame(id?: number, params?: any): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${id}`, {params});
  }

  getScanBarCodeHistory(campaignId, phone, target): Observable<any> {
    if (target) target = decodeURIComponent(target);
    this.setHeaderRequest();
    if (this.method_identificate === 'account_icheck') {
      return this.http.get(`${environment.urlDev}${environment.auth}customer/campaign/history-scan?campaign_id=${campaignId}`, { headers: this.header });
    }
    let link = `campaign_id=${campaignId}&phone=${phone}`;
    // if(!phone) {
    //   link = `campaign_id=${campaignId}&target=${target}`
    // }
    return this.http.get(`${environment.urlDev}${environment.public}campaign/code/history-scan?${link}`, { params: this.params });
  }

  getListEnterCodeHistory(campaignId, phone): Observable<any> {
    if (this.method_identificate === 'account_icheck') {
      return this.http.get(`${environment.urlDev}${environment.auth}customer/code?campaign_id=${campaignId}`, { headers: this.header });
    }
    return this.http.get(`${environment.urlDev}${environment.public}campaign/code/history-used-code?campaign_id=${campaignId}&phone=${phone}`, { params: this.params });
  }

  getGameDesignById(gameId:number): GameDesignConfig {
    const  defaultTheme = {
      background_game: `../../../../assets/images/luckywheel-ver2/main-bg-minigame.png`,
      background_gift:  `../../../../assets/images/luckywheel-ver2/main-bg-minigame.png`,
      background_rank: `../../../../assets/images/luckywheel-ver2/main-bg-minigame.png`,
      background_infor: `../../../../assets/images/luckywheel-ver2/main-bg-minigame.png`,
      background_history: `../../../../assets/images/luckywheel-ver2/main-bg-minigame.png`,
      luky_wheel :
    {
        icon_needle:  `../../../../assets/images/luckywheel-ver2/needle-minigame1.png`,
        icon_start: `/assets/images/luckywheel-ver2/start-minigame.png`,	
        border: `../../../../assets/images/luckywheel-ver2/background-minigame.png`,
        button_home: `../../../../assets/images/luckywheel-ver2/ic-home-minigame2.png`,
        button_gift: `../../../../assets/images/luckywheel-ver2/ic-my-gift-minigame2.png`,
        button_rank: `../../../../assets/images/luckywheel-ver2/ic-rank-minigame2.png`,
        button_info: `../../../../assets/images/luckywheel-ver2/ic-home-minigame2.png`,
        button_history: `../../../../assets/images/luckywheel-ver2/ic-home-minigame2.png`,
        is_show_gift_name: false
      },
      faintly_fall: [ ],
      wheel_config: {
          item_color: [['#FFFFFF', '#FFFFFF'], ['#FFFFFF', '#FFFFFF']],
          inner_stroke: ['#FFFFFF', '#FFFFFF'],
          cover_stroke: ['#FFFFFF', '#FFFFFF', '#FFFFFF', '#FFFFFF']
      },
      music:{
        default: "",
        play: "",
        end: ""
      },
      other: {
        rank_images : `../../../../assets/images/luckywheel-ver2/rank_image.png`
      }
    }
    return defaultTheme;
  }

  setHeaderRequest() {
    this.method_identificate = localStorage.getItem('method_identificate');
    const otpToken = localStorage.getItem('otpToken');
    if (this.method_identificate === 'info_sms') {
      this.params = {
        otpToken
      };
    }
    const access_token = localStorage.getItem('access_token');
    if (this.method_identificate === 'account_icheck') {
      this.access_token = access_token;
      this.header = new HttpHeaders({
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      })
    }
  }
}
