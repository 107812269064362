import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
  selector: 'app-vqmm',
  templateUrl: './vqmm.component.html',
  styleUrls: ['./vqmm.component.scss'],
})
export class VQMMComponent implements OnInit, OnChanges {
  @Input() data_game;
  @Input() currentCampaign;
  @Input() theme;
  @Input() dataDesignById;
  @Input() colorDefault;
  @Input() dataThemeDefault;
  @Input() time_play;
  @Input() isIframe;
  @Input() codeTheme;
  @Input() srcHeaderBackground;
  @Input() topWinnerStr;

  // tslint:disable-next-line:no-output-on-prefix
  @Output() onStartGame = new EventEmitter();
  @Output() onModalAddTimePlay = new EventEmitter();


  ngOnInit(): void {
  }

  startGame() {
    this.onStartGame.emit('start');
  }

  showModalAdd() {
    this.onModalAddTimePlay.emit('time_play');
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log("this.data_game", this.data_game)
    // console.log("this.colorDefault", this.colorDefault)
  }


}
