import { ModalController } from '@ionic/angular';
import { LuckyWheelService } from '../../services';
import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BuyCardComponent } from '../../../share';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { PopupReceiveGiftSuccessComponent } from '../../../gift-detail/components/popup-receive-gift-success/popup-receive-gift-success.component';
import { GiftService } from '../../../gift-detail/services/gift-detail.service';
import { ShareService } from '../../../share/services';
import { track } from "../../../../helpers/tracking-teko";

@Component({
  selector: 'app-reward-setting-modal',
  templateUrl: './reward-setting-modal.component.html',
  styleUrls: ['./reward-setting-modal.component.scss'],
  providers: [LuckyWheelService],
})
export class RewardSettingModalComponent implements OnInit {
  typeGift = '';
  btnTitleTypeMiniGameMKT = '';
  redirect_action;
  device_id: string;
  isDisable = false;
  otpToken: string;
  constructor(
    private luckywheelService: LuckyWheelService,
    private modalController: ModalController,
    private router: Router,
    private giftService: GiftService,
    private shareService: ShareService,
    private location: Location,
    private route: ActivatedRoute
  ) {
    this.shareService.deviceId.subscribe(deviceId => this.device_id = deviceId);
    const params = this.route.snapshot.queryParams;
    if (params.token && params.token !== 'undefined' && params.token !== 'null') {
      this.otpToken = params.token;
    }
  }

  @Input() configRewardForm;
  @Input() listGiftInput;
  @Input() campaignName;
  @Input() target;
  @Input() campaignId;
  @Input() code;
  @Input() campaign;
  @Input() parent_campaign_id;
  @Input() phone;
  // tslint:disable-next-line:variable-name
  @Input() winner_id;
  @Input() type;

  imageAsset = '../../../../../assets/images/luckywheel/step5/';
  isArrayGift = true;
  listGift = [];

  defaultButtonItem: any;
  optionButtonItem: any;

  ngOnInit() {
    console.log('configRewardForm', this.configRewardForm);

    this.defaultButtonItem = this.configRewardForm?.filter(item => item?.code === 'DEFAULT_BUTTON_ITEM')?.length ?
      this.configRewardForm?.filter(item => item?.code === 'DEFAULT_BUTTON_ITEM')[0] : null;

    this.optionButtonItem = this.configRewardForm?.filter(item => item?.code === 'OPTIONAL_BUTTON_ITEM')?.length ?
      this.configRewardForm?.filter(item => item?.code === 'OPTIONAL_BUTTON_ITEM')[0] : null;

    // console.log("optionButtonItem", this.optionButtonItem);

    if (this.campaign?.settings?.redirect_action) {
      let times_win = this.campaign?.settings?.redirect_action[0]?.redirect_times;
      let times_gift = this.campaign?.settings?.redirect_action[1]?.redirect_times;
      this.redirect_action = this.campaign?.settings?.redirect_action[0];
      if (this.listGiftInput.data.gift.type === "CONGRATULATION") {
        if (this.campaign?.settings?.redirect_action[1].is_enabled) {
          if (times_win < times_gift || !this.redirect_action?.is_enabled) {
            this.redirect_action = this.campaign?.settings?.redirect_action[1];
          }
        }
      }
      localStorage.setItem('redirect_action_win', JSON.stringify(this.redirect_action));
      // localStorage.setItem('redirect_action', JSON.stringify(this.campaign?.settings?.redirect_action[1]))
    }
    let time_out = localStorage.getItem('count_win');
    if (this.redirect_action) {
      if (!this.redirect_action?.is_enabled || this.redirect_action?.redirect_times == time_out) {
        this.isDisable = true;
      }
      setTimeout(() => {
        this.isDisable = true;
      }, (this.campaign?.settings?.redirect_action[0]?.redirect_timeout) * 1000);
    }
    const element = document.getElementsByClassName('modal-shadow')[0] as HTMLElement;
    if (element) {
      element.style.top = '100%';
    }

    if (this.listGiftInput && this.listGiftInput.statusCode === 200) {
      if (this.listGiftInput.data.gifts.length > 0 && this.listGiftInput.data.gifts.length < 2) {
        this.isArrayGift = false;
        this.listGift = this.listGiftInput.data.gifts;
      }
      const gift = this.listGiftInput.data.gift;
      this.typeGift = gift?.type || '';
      // console.log('gift: ', gift);
      // LOYALTY20-2263 add thêm quà voucher để hiển thị button nhận quà
      // if (['PRODUCT', 'ICOIN', 'VOUCHER'].includes(this.typeGift)) {
      //   this.btnTitleTypeMiniGameMKT = 'Nhận quà';
      // } else if (['PHONE_CARD', 'RECEIVE_STORE', 'VNPAY_QR'].includes(this.typeGift)) {
      //   this.btnTitleTypeMiniGameMKT = 'Xem quà';
      // } else if (['CONGRATULATION'].includes(this.typeGift)) {
      //   this.btnTitleTypeMiniGameMKT = null;
      // }
    }
  }

  async redirectV2() {

    // vòng quay may mắn chỉ sang 1 thôi
    const gift = this.listGift[0];
    const type_gift = gift.type;
    const gift_id = gift.id || null;
    const winner_id = this.listGiftInput.data.winner.id || null;
    const winner_gift_id = this.listGiftInput.data.winner_gift_id || null;
    await this.modalController.dismiss();

    track('manualTrackInteractionContent', {
      contentName: 'Xem qua',
      interaction: 'click',
      regionName: 'gainGiftPopup',
      target: 'giftDetail',
      payload: JSON.stringify({
        giftName: gift?.name,
        campaignId: this.campaignId,
        campaignName: this.campaignName
      })
    });

    const params: any = {
      phone: this.phone,
      parent_campaign_id: this.parent_campaign_id
    };
    if (['PRODUCT', 'ICOIN', 'VOUCHER'].includes(this.typeGift)) {
      if (this.campaign.information_fields?.receiving_gifts) {
        console.log(params);
        await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}/popup-receive-gift`], { queryParams: params });
      } else {
        await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/null/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
      }
    }
    if (this.typeGift === 'PHONE_CARD' || this.typeGift === 'RECEIVE_STORE' || this.typeGift === 'VNPAY_QR') {
      await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/${this.code}/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
    }
    if (this.listGiftInput.data.gift.type === "RECEIVE_STORE" && this.campaign?.settings?.redirect_action?.length) {
      localStorage.setItem('redirect_action', JSON.stringify(this.campaign?.settings?.redirect_action[1]));
    }

    if (this.typeGift === 'CONGRATULATION') {
      await this.router.navigate([`gift-detail/${this.campaignId}/${(this.target)}/1/null/${gift_id}/${winner_id}/${winner_gift_id}`], { queryParams: params });
    }
  }

  goToCampaignInfoPage() {
    track('manualTrackInteractionContent', {
      contentName: 'Chi tiet chuong trinh',
      interaction: 'click',
      regionName: 'gainGiftPopup',
      target: 'campaignInformation',
      pageTitle: 'giftName',
      payload: JSON.stringify({
        giftName: this.listGift[0]?.name,
        campaignId: this.campaignId,
        campaignName: this.campaignName
      })
    });
    this.modalController.dismiss();
    this.router.navigate([`gift-detail/landing-page-info/${(this.target)}/${this.type}/${this.code}`], {
      queryParams: {
        phone: this.phone,
        campaign_id: this.campaignId
      }
    });
  }

  dismiss() {
    this.modalController.dismiss();
  }

  getContent(content) {
    return content?.length ? content.replace(/null/g, '') : '';
  }

}
