import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ObjectUnsubscribedError, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { isNumeric } from '../../share';

@Injectable()
export class GiftService {
  constructor(
    private http: HttpClient
  ) { }
  API = {
    urlDev: 'https://api.dev.icheck.vn',
    public: '/api/business/public/loyalty/',
    auth: '/api/business/loyalty/'
  }
  params = {};
  header;
  method_identificate: string;
  urlTemplateMKT = environment.urlTemplateMKT;

  getCampaign(param, params?: any): Observable<any> {
    console.log('param', param);
    const object: any = {};
    if (param.campaign_id && param.campaign_id !== 'null') {
      return this.http.get(`${environment.urlDev}${environment.public}campaign/${param.campaign_id}`, {params});
    } else if (param.campaign_code && param.campaign_code !== 'null') {
      object.campaign_code = param.campaign_code;
      if (params?.form) {
        object.form = params.form;
      }
      if (params?.description) {
        object.description = params.description;
      }
      return this.http.get(`${environment.urlDev}${environment.public}campaign/get-campaign`, { params: object });
    } else {
      object.target = decodeURIComponent(param.target);
      if (params?.form) {
        object.form = params?.form;
      }
      if (params?.description) {
        object.description = params.description;
      }
    }
    return this.http.get(`${environment.urlDev}${environment.public}campaign/get-campaign`, { params: object })
  }

  getDetailWinnerGift(params): Observable<any> {
    this.params = {};
    this.setHeaderRequest();
    const query = {
      ...this.params,
      ...params
    }
    query.target ? query.target = decodeURIComponent(query.target) : {};
    if (this.method_identificate === 'account_icheck') {
      return this.http.get(`${environment.urlDev}${environment.auth}winner/gift/${params.winner_gift_id}/webapp`, { params: query, headers: this.header });
    }
    // Đây sử dụng WINNER GIFT ID để gọi lên chi tiết của Quà lúc đó có lúc WINNER ID
    return this.http.get(`${environment.urlDev}${environment.public}campaign/gift-detail/${params.winner_gift_id}`, { params: query })
  }

  getDetailGift(params): Observable<any> {
    this.setHeaderRequest();
    const query = {
      ...this.params,
      ...params
    }
    // if (this.method_identificate === 'account_icheck') {
    //   return this.http.get(`${environment.urlDev}${environment.auth}campaign/${campaign_id}/gift/${gift_id}`, {params: this.params, headers: this.header});
    // }
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${params.campaignId}/gift/${params.giftId}`, { params: query })
  }

  getCities(): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}cities?limit=100`);
  }

  getDistricts(cityId): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}districts?city_id=${cityId}&limit=100`);
  }

  getWards(districtId): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}wards?district_id=${districtId}&limit=100`);
  }

  getForm(campaignId: number) {
    return this.http.get<any>(`${environment.urlDev}/api/business/public/loyalty/campaign/${campaignId}?form=true`);
  }

  // API danh sách cửa hàng
  getStores(campaignId): Observable<any> {
    return this.http.get<any>(`${environment.urlDev}/api/business/public/loyalty/campaign/list/store?owner_icheck_id=${campaignId}`);
  }

  // API setting popup trúng thưởng
  geSettingPopup(campaignId): Observable<any> {
    return this.http.get<any>(`${environment.urlDev}/api/business/loyalty/campaign/${campaignId}/config/reward/form`);
  }

  getTopWinner(campaignId): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}winner/top_winner?campaign_id=${campaignId}`);
  }

  getLastestWinner(campaignId): Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${campaignId}/recent-get-gift`);
  }

  getAllGiftOfCampaign(campaignId, phoneNumber, target, device_id?): Observable<any> {
    this.setHeaderRequest();
    if (this.method_identificate === 'account_icheck') {
      return this.http.get(`${environment.urlDev}${environment.auth}campaign/gifts?campaign_id=${campaignId}&limit=100&offset=0`, { params: this.params, headers: this.header });
    }
    let link = (phoneNumber) ? `phone=${phoneNumber}&` : '';
    if (!phoneNumber && target && target !== 'null') {
      link += `target=${decodeURIComponent(target)}&device_id=${device_id}&`;
    } else {
      link += `device_id=${device_id}&`;
    }
    link += `limit=100&offset=0`;
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${campaignId}/all-gift?${link}`, { params: this.params });
  }


  getAllGift(params, otpToken: string = '', method: string = ''): Observable<any> {
    this.params = {};
    this.setHeaderRequest().then();
    const query = {
      ...this.params,
      ...params
    };
    if (!query.otpToken && method === 'info_sms') {
      query.otpToken = otpToken;
    }
    // if (query.code) {
    //   query.target = `${environment.urlTemplateMKT}${query.target}`;
    // }
    query.target ? query.target = decodeURIComponent(query.target) : {};
    if (this.method_identificate === 'account_icheck') {
      // tslint:disable-next-line:max-line-length
      return this.http.get(`${environment.urlDev}${environment.auth}campaign/gifts?campaign_id=${params.campaignId}`, { params: query, headers: this.header });
    }
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${query.campaignId}/all-gift`, { params: query });
  }

  refuseGift(winner_id, phone, device_id?): Observable<any> {
    this.setHeaderRequest();
    if (this.method_identificate === 'account_icheck') {
      return this.http.get(`${environment.urlDev}${environment.auth}customer/winner/${winner_id}/refuse-gift`, { params: this.params, headers: this.header });
    }
    // return this.http.post(`${environment.urlDev}${environment.public}winner/${winner_id}/refuse-gift?phone=${phone}`, {
    //   params: this.params,
    //   device_id: device_id
    // });
    // tuan fix ngay 28/05/2021 chỉ có device_id ở trong body còn phone và otp owrr query param
    return this.http.post(`${environment.urlDev}${environment.public}winner/${winner_id}/refuse-gift?phone=${phone}&otpToken=${(this.params as any)?.otpToken}`, { device_id });
  }

  acceptReceiveGift(body): Observable<any> {
    this.setHeaderRequest();
    if (body.target) body.target = decodeURIComponent(body.target);
    if (this.method_identificate === 'account_icheck') {
      return this.http.post(`${environment.urlDev}${environment.auth}campaign/customer-receive-gift`, body, { params: this.params, headers: this.header });
    }
    return this.http.post(`${environment.urlDev}${environment.public}campaign/receive-gift`, body, { params: this.params });
  }

  exchangeGift(body): Observable<any> {
    this.setHeaderRequest();
    const query = {
      ...this.params,
      ...body
    };

    // if (query.code) { query.target = `${environment.urlTemplateMKT}${query.target}`; }
    query.target = decodeURIComponent(query.target);
    // if (this.method_identificate === 'account_icheck') {
    // tslint:disable-next-line:max-line-length
    //   return this.http.post(`${environment.urlDev}${environment.auth}customer/campaign/exchange/gift`, body, {params: query, headers: this.header});
    // }
    return this.http.post(`${environment.urlDev}${environment.public}campaign/exchange/gift`, query, { params: query });
  }

  getTotalPoint(params): Observable<any> {
    this.setHeaderRequest();
    const query = {
      ...this.params,
      ...params
    }
    // if (query.code) query.target = `${environment.urlTemplateMKT}${query.target}`
    query.target = decodeURIComponent(query.target);
    if (this.method_identificate === 'account_icheck') {
      return this.http.get(`${environment.urlDev}${environment.auth}customer/campaign/${query.campaignId}/accumulate/info`, { params: query, headers: this.header });
    }
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${query.campaignId}/total-points`, { params: query });
  }
  async setHeaderRequest() {
    this.method_identificate = localStorage.getItem('method_identificate');
    const otpToken = localStorage.getItem('otpToken');
    if (this.method_identificate === 'info_sms') {
      this.params = {
        otpToken
      };
    }
    // tslint:disable-next-line:variable-name
    const access_token = localStorage.getItem('access_token');
    if (this.method_identificate === 'account_icheck') {
      this.header = new HttpHeaders({
        Authorization: `Bearer ${access_token}`,
        'Content-Type': 'application/json'
      });
    }
  }

  getTheme(id:number):Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${id}/themes`);
  }
  getThemeGame(id:number):Observable<any> {
    return this.http.get(`${environment.urlDev}${environment.public}campaign/${id}/theme_game`);
  }
}
