import {LotteryGiftsComponent} from './layout/lottery-gifts/lottery-gifts.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AppGiftExchangesComponent} from './layout/vnpoints/app-gift-exchanges/app-gift-exchanges.component';
import {ScreenOrientation} from '@ionic-native/screen-orientation/ngx';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GiftService} from './layout/gift-detail/services/gift-detail.service';
import {ShareModule} from './layout/share/share.module';
import {LocationInterceptor} from 'src/app/location.interceptor';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {CheckVerifyUser} from './public/lib';
// import * as AllIcons from '@ant-design/icons-angular/icons';
// import {IconDefinition} from '@ant-design/icons-angular';
import {CommonService} from './public/service/common.service';
import {CampaignDetailComponent} from './layout/campaign/campaign-detail/campaign-detail.component';
import {HistoryComponent} from './layout/campaign/history/history.component';
import {MyGiftComponent} from './layout/campaign/my-gift/my-gift.component';
import {LandingComponent} from './layout/landing/landing.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {GiftDetailVnpointsComponent} from './layout/vnpoints/gift-detail-vnpoints/gift-detail-vnpoints.component';
import {LackOfVnpointsComponent} from './layout/vnpoints/layouts/lack-of-vnpoints/lack-of-vnpoints.component';
import {MyGiftAndHistoryComponent} from './layout/campaign/my-gift-and-history/my-gift-and-history.component';
import { en_US, NgZorroAntdModule, NzIconModule, NZ_I18N } from 'ng-zorro-antd';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {LuckyWheelService} from "./layout/luckywheel/services";
import {VQMMComponent} from "./vqmm/vqmm.component";
import {RewardModalComponent} from "./layout/luckywheel/components/reward-modal/reward-modal.component";
// const antDesignIcons = AllIcons as {
//   [key: string]: IconDefinition;
// };

// const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => {
//   return antDesignIcons[key];
// });
const SERVICE = [CheckVerifyUser, LuckyWheelService];


@NgModule({
    declarations: [
        AppComponent,
        CampaignDetailComponent,
        HistoryComponent,
        MyGiftComponent,
        MyGiftAndHistoryComponent,
        AppGiftExchangesComponent,
        LandingComponent,
        GiftDetailVnpointsComponent,
        LackOfVnpointsComponent,
        LotteryGiftsComponent,
        VQMMComponent,
        RewardModalComponent
    ],
  entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        HttpClientModule,
        ShareModule,
        NzIconModule,
        FormsModule,
        BrowserAnimationsModule,
        NgZorroAntdModule,
        InfiniteScrollModule,
        ReactiveFormsModule
    ],
  providers: [
    StatusBar,
    SplashScreen,
    ScreenOrientation,
    GiftService,
    Geolocation,
    CommonService,
    {provide: NZ_I18N, useValue: en_US},
    { provide: HTTP_INTERCEPTORS, useClass: LocationInterceptor, multi: true },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ...SERVICE,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
