import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import * as moment from 'moment';
import { AlertComponent } from '../../share/alert/alert.component';
import { ModalController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { ShareService } from '../../share/services';
import { OtpComponent, VerifyPhoneComponent } from '../../share';
import { Location } from '@angular/common'
import {environment} from "../../../../environments/environment";
import {GoogleAnalyticService} from '../../google-analytic-service/google-analytic.service';
import {DomSanitizer} from '@angular/platform-browser';
@Component({
  selector: 'app-campaign-detail',
  templateUrl: './campaign-detail.component.html',
  styleUrls: ['./campaign-detail.component.scss'],
})
export class CampaignDetailComponent implements OnInit, OnDestroy {
  scenarios: any = [];
  campaignId: any;
  campaign: any;
  statusOutDate: any;
  businessOwnerID: any;
  isLoading: boolean = false;
  phone: any;
  // phoneDefault: any;
  deviceId: any;
  business_id: any;
  is_change_phone: any;
  isIframe: any;
  metadata: any;
  lottery_code: any;
  source: any;
  type: any;
  constructor(public modalController: ModalController,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private googleAnalyticService: GoogleAnalyticService,
    private sanitized: DomSanitizer,
    private shareService: ShareService) {
    // get path
    this.campaignId = this.route.snapshot.params.id;
    this.business_id = this.route.snapshot.queryParams.business_id;
    this.is_change_phone = this.route.snapshot.queryParams.is_change_phone;
    this.isIframe = this.route.snapshot.queryParams.isIframe;
    this.metadata = this.route.snapshot.queryParams.metadata;
    this.lottery_code = this.route.snapshot.queryParams.lottery_code;
    this.source = this.route.snapshot.queryParams.source;
    this.type = this.route.snapshot.queryParams.type; /* type = view dùng cho giới hạn local để xem thông tin chương trình*/

    const currentGift = JSON.parse(localStorage.getItem('currentGift'));
    const lotteryCode = JSON.parse(sessionStorage.getItem('lottery_code'));

    if (lotteryCode && lotteryCode.campaignId === this.campaignId) {
      this.lottery_code = lotteryCode.lottery_code;
    }

    if (this.lottery_code && this.campaignId) {
      sessionStorage.setItem('lottery_code', JSON.stringify({
        campaignId: this.campaignId,
        lottery_code: this.lottery_code
      }));
    }
    if (this.is_change_phone) {
      localStorage.setItem('is_change_phone', JSON.stringify(this.is_change_phone));
    }
    // localStorage.setItem('campaignId', this.campaignId)
    //get query param
    this.phone = this.route.snapshot.queryParams.phone;
    // this.phoneDefault = this.route.snapshot.queryParams.phoneDefault;
    localStorage.removeItem('phone_info_required');
    // if (this.phone) {
    //   localStorage.setItem('phone_info_required', this.phone);
    // }
    this.shareService.deviceId.subscribe(deviceId => this.deviceId = deviceId);
    if (this.route.snapshot.queryParams?.token || localStorage.getItem('otpToken')) {
      if (this.route.snapshot.queryParams?.token) {
        localStorage.setItem('otpToken', this.route.snapshot.queryParams?.token);
        localStorage.setItem('userVery', JSON.stringify([this.phone]));
      }
      localStorage.removeItem('access_token');
    } else if (this.route.snapshot.queryParams?.access_token || localStorage.getItem('access_token')) {
      if (this.route.snapshot.queryParams?.access_token) {
        localStorage.setItem('access_token', this.route.snapshot.queryParams?.access_token);
      }
      localStorage.removeItem('otpToken');
    } else {
      // localStorage.removeItem('otpToken');
      // localStorage.removeItem('access_token');
    }
  }

  checkCDN() {
    if (this.isIframe === 'true' && this.campaignId) {
      this.shareService.campaignIdCdn.next(this.campaignId);
      this.shareService.metadata.next(this.metadata);
      this.shareService.totalCDN.next(new Date());
    }
  }

  ngOnInit() {
    this.checkCDN();
    Promise.all([
      this.loadCampaign(),
      this.loadScenario(),
    ]).then(async _ => {
      this.isLoading = true;
      // if (this.campaign?.campaign_condition?.web_app_condition === 'info_otp_required' && !localStorage.getItem('otpToken')) {
      if (this.isIframe !== 'true') {
        if (this.is_change_phone === 'false') {
          return;
        }
        if (this.type === 'view') {
          return;
        }
        if (this.campaign?.campaign_condition?.web_app_condition === 'info_otp_required' && !JSON.parse(localStorage.getItem('userVery'))?.filter(item => item === this.phone)?.length && !localStorage.getItem('otpToken')) {
          // cần xác mình sdt khi yêu cầu OTP
          const modal = await this.modalController.create({
            // component: VerifyPhoneComponent,
            component: OtpComponent,
            cssClass: 'popup-confirm-phone',
            backdropDismiss: false,
            componentProps: {
              // campaign_id: this.campaignId,
              user_id: this.campaign?.owner?.id,
              phoneDefault: this.phone,
              isVeryUser: true
            }
          });
          modal.onDidDismiss().then(async data => {
            if (data?.data?.phone || data?.data?.isSamePhone) {
              await this.router.navigate([`campaign/${this.campaignId}`], { queryParams: { phone: data?.data?.phone || this.phone } });
            } else {
              // this.location.back();
              location.reload();
            }
          });
          return await modal.present();
        }

        if (this.campaign?.campaign_condition?.web_app_condition === 'info_required' && !this.phone) {
          // cần xác phone khi yêu cầu nhập thông tin tham gia chương trình
          const modal = await this.modalController.create({
            component: VerifyPhoneComponent,
            cssClass: 'popup-confirm-phone',
            backdropDismiss: false,
            componentProps: {
              phone: this.phone
            }
          });
          modal.onDidDismiss().then(async data => {
            if (data?.data?.phone) {
              // localStorage.setItem('phone_info_required', data?.data?.phone);
              this.phone = data?.data?.phone;
              await this.router.navigate([`campaign/${this.campaignId}`], { queryParams: { phone: data?.data?.phone } });
            } else {
              location.reload();
              // this.location.back();
              // location.reload();
              // await this.router.navigate([`campaign/${this.campaignId}`]);
            }
            // location.reload();
          });
          return await modal.present();
        }
      }
    }).catch(_ => { this.isLoading = true; });
  }

  isCampaignAll = false;
  loadCampaign() {
    return this.shareService.getCampaign(this.campaignId).toPromise().then((res: any) => {
      if (res?.statusCode === 200) {
        this.campaign = res?.data;
        this.campaign.description = this.sanitized.bypassSecurityTrustHtml(this.campaign?.description);
        // this.googleAnalyticService.initialize(this.campaign.owner?.id_ga);
        try {
          // this.googleAnalyticService.event('view_campaign_detail', {
          //   campaign_id: this.campaign.id,
          //   business_id: this.campaign.owner.id
          // });
        } catch (e) {
          console.log(e);
        }
        this.businessOwnerID = this.campaign?.owner?.id;
        this.isCampaignAll = this.campaign?.owner?.id === environment.userIdCampaignAll;
      }
      this.statusOutDate = res.status;
    });
  }


  loadScenario() {
    return this.shareService.getScenario(this.campaignId).toPromise().then((res) => {
      if (res?.statusCode === 200) {
        this.scenarios = res?.data?.rows;
      }
    });
  }

  async onMyGiftOrHistory(type) {
    const is_change_phone = JSON.parse(localStorage.getItem('is_change_phone'));
    await this.router.navigate([`campaign/${this.campaignId}/${this.campaign?.owner?.id || this.business_id}/my-gift-and-history`],
      { queryParams: {
          phone: this.phone,
          is_change_phone: this.is_change_phone || is_change_phone,
          activeTab: type === 'gift' ? 2 : 1,
          lottery_code: this.lottery_code,
          source: this.source
      } });
  }

  // async onMyGift() {
  //   const is_change_phone = JSON.parse(localStorage.getItem('is_change_phone'));
  //   await this.router.navigate([`campaign/${this.campaignId}/${this.campaign?.owner?.id || this.business_id}/my-gift`],
  //       { queryParams: {
  //           phone: this.phone,
  //           is_change_phone: this.is_change_phone || is_change_phone,
  //           lottery_code: this.lottery_code,
  //           source: this.source
  //       } });
  // }
  //
  // async onHistory() {
  //   const is_change_phone = JSON.parse(localStorage.getItem('is_change_phone'));
  //   await this.router.navigate([`campaign/${this.campaignId}/history`], {
  //     queryParams: {
  //       phone: this.phone,
  //       device_id: this.deviceId,
  //       business_id: this.campaign?.owner?.id || this.business_id,
  //       is_change_phone: this.is_change_phone || is_change_phone,
  //       lottery_code: this.lottery_code,
  //       source: this.source
  //     } });
  // }

  isShow(startAt) {
    return moment().isBefore(moment(startAt));
  }

  isShowEndAt(endAt) {
    return moment().isAfter(moment(endAt));
  }

  isDate(startAt, endAt) {
    if (!startAt && !endAt) {
      return true;
    }
    return moment().isSameOrAfter(moment(startAt)) && moment().isSameOrBefore(moment(endAt));
  }

  async join(scenario) {
    this.checkCDN();
    if (this.isIframe === 'true' && this.campaignId) {
      return;
    }
    if (moment().isBefore(moment(scenario?.start_at))) {
      // return;
      await this.showAlert('Sự kiện chưa bắt đầu', 'Bạn hãy quay lại sau nhé !');
    } else if (moment().isAfter(moment(scenario?.end_at))) {
      await this.showAlert('Sự kiện đã kết thúc', 'Lần sau nhanh tay hơn nhé !');
      // return;
    } else {
      const phone = this.phone ? this.phone : '';
      if (scenario?.type === 'mini_game_qr_mar') {
        // https://webapp-loyalty.dev.icheck.vn/luckywheel/undefined/null/1?phone=0964772195&campaignId=3181&deviceId=9ba7aca0dc2b4e8efb35157a75aa4ac0&type=info
        await this.router.navigate([`luckywheel/undefined/null/1`],
            { queryParams: {
                phone,
                campaignId: scenario?.id,
                deviceId: this.deviceId,
                type: 'info',
                parent_campaign_id: this.campaignId,
                lottery_code: this.lottery_code,
                source: this.source
            } });
        return;
      }

      if (scenario?.type === 'accumulate_point_qr_mar') {
        // https://webapp-loyalty.dev.icheck.vn/accumulate-point/undefined/null/1/null/3278?phone=0964772195
        await this.router.navigate([`accumulate-point/undefined/null/1/null/${scenario?.id}`],
            { queryParams: {
                phone,
                deviceId: this.deviceId,
                parent_campaign_id: this.campaignId,
                lottery_code: this.lottery_code,
                source: this.source
            } });
        return;
      }
    }
  }

  async showAlert(message, title) {
    const modal = await this.modalController.create({
      component: AlertComponent,
      cssClass: 'reward',
      componentProps: {
        message,
        title
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }

  async viewLanding() {
    const is_change_phone = JSON.parse(localStorage.getItem('is_change_phone'));
    await this.router.navigate([`landing/${this.campaign?.owner?.id || this.business_id}`],
        { queryParams: { phone: this.phone, is_change_phone: this.is_change_phone || is_change_phone, lottery_code: this.lottery_code } });
    // if (this.phone) {
    //   // const phone = localStorage.getItem('phone_info_required');
    //   await this.router.navigate([`landing/${this.campaign?.owner?.id || this.business_id}`], {queryParams: {phone: this.phone, is_change_phone: this.is_change_phone}});
    // } else {
    //   await this.router.navigate([`landing/${this.campaign?.owner?.id || this.business_id}`], {queryParams: {is_change_phone: this.is_change_phone}});
    // }
    // localStorage.removeItem('phone_info_required');
    // localStorage.removeItem('otpToken');
    // localStorage.removeItem('access_token');
  }

  ngOnDestroy(): void {
  }

}
