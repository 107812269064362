import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GiftService } from '../../gift-detail/services/gift-detail.service';
import { ShareService } from 'src/app/layout/share/services';
import { ModalController } from '@ionic/angular';
import {GoogleAnalyticService} from '../../google-analytic-service/google-analytic.service';
import {environment} from '../../../../environments/environment';
declare const grecaptcha: any;
@Component({
  selector: 'app-buy-card',
  templateUrl: './buy-card.component.html',
  styleUrls: ['./buy-card.component.scss'],
})
export class BuyCardComponent implements OnInit {
  // tslint:disable-next-line:variable-name
  @Input() campaign_id: string;
  // tslint:disable-next-line:variable-name
  @Input() gift_id: string;
  @Input() phoneUser: string;
  @Input() typeGift: string;
  // tslint:disable-next-line:variable-name
  @Input() winner_id: any;
  @Input() winner_status: string;
  @Input() manual_release: boolean
  @Input() typeCard: any;
  @Input() request: any = undefined;
  step: number;
  result: any;
  campaign_ids: any;
  target: any;
  currentCampaign: any;
  // tslint:disable-next-line:variable-name
  type_gift: string;
  deviceId: string;
  constructor(
    private giftService: GiftService,
    private route: ActivatedRoute,
    private shareService: ShareService,
    private modalController: ModalController,
    private googleAnalyticService: GoogleAnalyticService
  ) {
    this.target = (this.route.snapshot.paramMap.get('target'));
    this.shareService.deviceId.subscribe(deviceId => this.deviceId = deviceId);
  }

  ngOnInit() {
    this.giftService.getCampaign({ target: this.target }).subscribe(res => {
      this.currentCampaign = res;
      console.log("currentCampaign", res)
    });
    this.type_gift = this.typeGift;
    console.log(`this.typeGift = ${this.typeGift}`);
    console.log(`this.type_gift = ${this.type_gift}`);
    this.step = 1;
    console.log("winner_status", this.winner_status);
    console.log("manual_release", this.manual_release);
    
  }

  afterStep1(event) {
    this.type_gift = event.type_gift;
    console.log('xxxxxxxxxxxxx', this.type_gift);
    console.log('typeGift', this.typeGift);
    console.log('typeCard', this.typeCard);
    grecaptcha.enterprise.ready(async () => {
      const actionCaptcha = 'BUY_CARD_TOPUP';

      try {
        const tokenCaptcha = await grecaptcha.enterprise.execute(environment.keyCaptcha, { action: actionCaptcha });
        console.log('token', tokenCaptcha);

        /* gift_live không dùng cho tích điểm đổi quà */
        if (this.typeGift === 'gift_live') {
          const object = {
            winner_id: this.winner_id,
            winner_phone: this.phoneUser,
            phone: this.phoneUser,
            device_id: this.deviceId,
            serviceId: event.serviceId,
            ...this.request,
            receiver_phone: event.phone,
            type_card: event.type_gift,
            tokenCaptcha,
            actionCaptcha
          };

          this.giftService.acceptReceiveGift(object).subscribe(
              async res => {
                if (res.statusCode === 200) {
                  // if (res?.data?.campaign_id) {
                  //   this.googleAnalyticService.initialize(res?.data?.business_owner_id);
                  //   this.googleAnalyticService.event('topup_card', {
                  //     campaign_id: res?.data?.campaign_id,
                  //     business_id: res?.data?.business_owner_id,
                  //     created_time: new Date()
                  //   });
                  // }
                  this.campaign_ids = res.data.campaign_id;
                  if (this.typeCard === 'TOPUP' || this.typeCard === 'PHONE_CARD') {
                    this.result = {
                      statusCode: res.statusCode,
                      message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
                      data: res.data.gift,
                      phone: event.phone,
                      provider: event?.provider,
                      logPhonecard: res?.data?.logPhonecard
                    };
                    this.step = 3;
                    return;
                  } else {
                    await this.modalController.dismiss({ statusCode: 200 });
                    return;
                  }
                }
                this.result = {
                  statusCode: 400,
                  message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
                  phone: event.phone
                };
                this.step = 3;
              },
              error => {
                this.result = {
                  statusCode: 400,
                  message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
                  phone: event.phone
                };
                this.step = 3;
              }
          );
          return;
        }

        const body = {
          campaign_id: this.campaign_id,
          gift_id: this.gift_id,
          winner_phone: this.phoneUser,
          device_id: this.deviceId,
          phone: this.phoneUser,
          serviceId: event.serviceId,
          ...this.request,
          receiver_phone: event.phone,
          type_card: event.type_gift,
          tokenCaptcha,
          actionCaptcha
        };

        this.giftService.exchangeGift(body).subscribe(
            async res => {
              if (res.statusCode === 200) {
                if (this.typeCard === 'TOPUP') {
                  this.result = {
                    statusCode: res.statusCode,
                    message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
                    data: res.data.gift,
                    phone: event.phone,
                    provider: event?.provider,
                    logPhonecard: res?.data?.logPhonecard
                  };
                  this.step = 3;
                  return;
                } else {
                  await this.modalController.dismiss({ statusCode: 200 });
                  return;
                }
              }
              this.result = {
                statusCode: 400,
                message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
                phone: event.phone
              };
              this.step = 3;
            },
            error => {
              this.result = {
                statusCode: 400,
                message: 'Có lỗi trong quá trình nạp thẻ Bạn vui lòng thử lại sau',
                phone: event.phone
              };
              this.step = 3;
            }
        );
      } catch (error) {
        console.error('Error executing reCAPTCHA:', error);
      }
    });

  }
  retryCard(event) {
    this.step = 1;
  }
}
